export enum REGEX_TYPE {
  DECIMAL = 'decimal',
  INTEGER = 'integer'
}

export enum POSITION {
  LEFT = 'left',
  RIGHT = 'right'
}

export enum TOASTER_TYPE {
  INFO = 'info',
  ERROR = 'error',
  WARN = 'warning',
  SUCCESS = 'success'
}

export enum EXHIBITION_TABS {
  ALL = 'all',
  ONGOING = 'ongoing',
  UPCOMING = 'upcoming',
  PAST = 'past'
}
