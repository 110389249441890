import { environment } from '@environment/environment';

export const API = {
  KEYWORDS: `${environment.authApi}/api/web-keywords/`,
  ABOUT_US_DETAIL: `${environment.authApi}/api/cms/web-about-us/details`,
  AGREEMENT: `${environment.authApi}/api/cms/web-home/agreement`,
  ART_GALLERY: `${environment.authApi}/api/art-gallery`,
  ARTEFACT_PDF: `${environment.authApi}/api/web-collection/artefact-detail-pdf`,
  ARTICLES: `${environment.authApi}/api/articles`,
  ARTIFACTS: `${environment.authApi}/api/web-collection`,
  ARTISTS: `${environment.authApi}/api/web-collection/artist`,
  BOOK_LIBRARY: `${environment.authApi}/api/book-library`,
  CATEGORY: `${environment.authApi}/api/web-collection/category`,
  COLLECTION_ARTIFACT: `${environment.authApi}/api/web-collection/artefact-detail`,
  COLLECTION: `${environment.authApi}/api/web-collection/artefact-detail/slider`,
  CONFIGS: `${environment.authApi}/api/constant`,
  CONTACT_US_CMS: `${environment.authApi}/api/cms/web-contact-us/details`,
  CONTACT_US: `${environment.authApi}/api/web-contact-us`,
  CONTRIBUTE: `${environment.authApi}/api/cms/web-contribute`,
  DONATE_GIFT: `${environment.authApi}/api/web-contribute/donate`,
  EDUCATION: `${environment.authApi}/api/education`,
  EMAIL_SUBSCRIPTION: `${environment.authApi}/api/web-email-subscription`,
  EXHIBITION: `${environment.authApi}/api/website-exhibition`,
  EXPLORE_COLLECTION: `${environment.authApi}/api/web-collection/explore`,
  FEEDBACK: `${environment.authApi}/api/web-feedback`,
  FORGOT_PASSWORD: `${environment.authApi}/api/web-authentication/forgot-password`,
  GALLERY: `${environment.authApi}/api/web-collection/gallery`,
  HEADING: `${environment.authApi}/api/web-collection/heading`,
  HISTORY_EDUCATION_WING: `${environment.authApi}/api/history-education`,
  HISTORY_EDUCATION: `${environment.authApi}/api/web-learn`,
  JOURNEY: `${environment.authApi}/api/cms/web-home/journey`,
  LANDING_VIDEO: `${environment.authApi}/api/cms/web-home/landing-video`,
  LOGIN: `${environment.authApi}/api/web-authentication/login`,
  MUSEUM_DETAILS: `${environment.authApi}/api/cms/web-home/museum-detail`,
  ORIGIN_YEAR: `${environment.authApi}/api/web-collection/originYear`,
  RESET_PASSWORD: `${environment.authApi}/api/web-authentication/reset-password`,
  SACRED_LIBRARY: `${environment.authApi}/api/sacred-library`,
  SHARE_ARTWORK: `${environment.authApi}/api/web-contribute/share-artwork`,
  SIGNUP: `${environment.authApi}/api/web-authentication/signup`,
  SITE_SETTINGS: `${environment.authApi}/api/config`,
  VISIBLE_GALLERY: `${environment.authApi}/api/web-home/gallery`,
  VISIBLE_HEADING: `${environment.authApi}/api/web-home/heading`,
  WHAT_WE_OFFER: `${environment.authApi}/api/cms/web-about-us/offer-details`,
  PAYMENT_CHECKOUT: `${environment.authApi}/api/payment/checkout`,
  PAYMENT: `${environment.authApi}/api/payment`,
  VERIFY_EMAIL: `${environment.authApi}/api/web-authentication/verify-email`,
  PRIVATE_VIEW: `${environment.authApi}/api/web-private-view`
};

export const CACHE_API = [
  API.ABOUT_US_DETAIL,
  API.AGREEMENT,
  API.CONTACT_US_CMS,
  API.CONTRIBUTE,
  API.HISTORY_EDUCATION_WING,
  API.JOURNEY,
  // API.LANDING_VIDEO,
  API.MUSEUM_DETAILS,
  API.VISIBLE_GALLERY,
  API.VISIBLE_HEADING,
  API.WHAT_WE_OFFER,
  API.CATEGORY
];
