<div class="backdrop-blur-[2px] fixed flex justify-center items-center inset-0 z-50">
  <div @fade class="fixed inset-0 bg-black/50" (click)="popupClose()" aria-hidden="true">
  </div>
  <div @openModel
    class="bg-white rounded-lg w-[480px] md:w-[585px] px-4 pb-4 pt-8 md:p-8 lg:p-10 mx-auto max-w-[calc(100%-32px)] max-h-[calc(100%-32px)] overflow-auto relative top-0">
    <div class="cursor-pointer absolute top-3 right-3" (click)="popupClose()" aria-hidden="true">
      <app-icons icons="close" width="16" height="16" />
    </div>
    <div class="flex items-center justify-between">
      <div class="pr-4">
        <strong class="block text-blue-custom text-lg md:text-xl lg:text-2xl uppercase">Forgot
          password</strong>
        <p class="text-black text-sm lg:text-base mt-1">Enter email to recover your
          account</p>
      </div>
      <img loading="eager" fetchpriority="high" class="w-[80px] sm:w-[100px] md:w-[120px]" [src]="awsUrl+logo"
        alt="logo">
    </div>
    <form class="mt-6 md:mt-7 lg:mt-8 xl:mt-10" [formGroup]="fpForm">
      <div>
        <app-vc-input formControlName="email" [customClass]="{
            'w-full px-4 text-neutral-700 rounded-md border outline-none min-h-[40px] border-grey-50' : true, 'border-red text-red' : formControls.email.touched && formControls.email.invalid
          }" type="email" placeholder="Email Address" name="email" [required]="true" [pattern]="emailRegex"
          autocomplete="email" />
        @if(formControls.email.touched && formControls.email.errors?.required) {
        <p class="text-red mt-1 text-xs md:text-sm"> Email is required </p>
        }
        @if(formControls.email.touched && formControls.email.errors?.pattern) {
        <p class="text-red mt-1 text-xs md:text-sm"> Please enter valid email </p>
        }
      </div>
      <div class="mt-6 md:mt-7 lg:mt-8 xl:mt-10 text-center">
        <app-vc-button [type]="'submit'"
          [class]="{ 'px-4 h-10 rounded-5 bg-primary text-white font-semibold rounded-md min-w-[100px] disabled:bg-grey-50 disabled:text-grey-light disabled:cursor-not-allowed' : true }"
          [isDisabled]="isSubmitted" [spin]="isSubmitted" (buttonTap)="submitForm()">
          Submit
        </app-vc-button>
      </div>
      <div class="mt-4 lg:mt-6 xl:mt-8 text-center">
        <app-vc-button [class]="{ 'text-sm xl:text-base underline uppercase font-medium' : true }"
          [isDisabled]="isSubmitted" (buttonTap)="backToLogin()">
          Back to Sign In
        </app-vc-button>
      </div>
    </form>
  </div>
</div>