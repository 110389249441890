@switch(icons) {
@case('search') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 122.879 119.799">
  <g>
    <path class="{{fill}}"
      d="M49.988,0h0.016v0.007C63.803,0.011,76.298,5.608,85.34,14.652c9.027,9.031,14.619,21.515,14.628,35.303h0.007v0.033v0.04 h-0.007c-0.005,5.557-0.917,10.905-2.594,15.892c-0.281,0.837-0.575,1.641-0.877,2.409v0.007c-1.446,3.66-3.315,7.12-5.547,10.307 l29.082,26.139l0.018,0.016l0.157,0.146l0.011,0.011c1.642,1.563,2.536,3.656,2.649,5.78c0.11,2.1-0.543,4.248-1.979,5.971 l-0.011,0.016l-0.175,0.203l-0.035,0.035l-0.146,0.16l-0.016,0.021c-1.565,1.642-3.654,2.534-5.78,2.646 c-2.097,0.111-4.247-0.54-5.971-1.978l-0.015-0.011l-0.204-0.175l-0.029-0.024L78.761,90.865c-0.88,0.62-1.778,1.209-2.687,1.765 c-1.233,0.755-2.51,1.466-3.813,2.115c-6.699,3.342-14.269,5.222-22.272,5.222v0.007h-0.016v-0.007 c-13.799-0.004-26.296-5.601-35.338-14.645C5.605,76.291,0.016,63.805,0.007,50.021H0v-0.033v-0.016h0.007 c0.004-13.799,5.601-26.296,14.645-35.338C23.683,5.608,36.167,0.016,49.955,0.007V0H49.988L49.988,0z M50.004,11.21v0.007h-0.016 h-0.033V11.21c-10.686,0.007-20.372,4.35-27.384,11.359C15.56,29.578,11.213,39.274,11.21,49.973h0.007v0.016v0.033H11.21 c0.007,10.686,4.347,20.367,11.359,27.381c7.009,7.012,16.705,11.359,27.403,11.361v-0.007h0.016h0.033v0.007 c10.686-0.007,20.368-4.348,27.382-11.359c7.011-7.009,11.358-16.702,11.36-27.4h-0.006v-0.016v-0.033h0.006 c-0.006-10.686-4.35-20.372-11.358-27.384C70.396,15.56,60.703,11.213,50.004,11.21L50.004,11.21z" />
  </g>
</svg>
}
@case('user') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 24 24">
  <g id="Layer_2" data-name="Layer 2" transform="translate(-0.989 -0.999)">
    <path class="{{fill}}" id="Path_36994" data-name="Path 36994"
      d="M14.8,14.6a4.8,4.8,0,1,1,4.8-4.8,4.8,4.8,0,0,1-4.8,4.8Zm0-8A3.2,3.2,0,1,0,18,9.8,3.2,3.2,0,0,0,14.8,6.6Z"
      transform="translate(-1.803 -0.8)" />
    <path class="{{fill}}" id="Path_36995" data-name="Path 36995"
      d="M13.578,28.552A12,12,0,0,1,4.306,24.16l-.416-.512.416-.5a12,12,0,0,1,18.543,0l.416.5-.416.512A12,12,0,0,1,13.578,28.552Zm-7.592-4.9a10.4,10.4,0,0,0,15.2,0,10.4,10.4,0,0,0-15.2,0Z"
      transform="translate(-0.58 -3.553)" />
    <path class="{{fill}}" id="Path_36996" data-name="Path 36996"
      d="M13,25a12,12,0,1,1,9.272-4.392A12,12,0,0,1,13,25ZM13,2.6A10.4,10.4,0,1,0,23.4,13,10.4,10.4,0,0,0,13,2.6Z"
      transform="translate(0 0)" />
    <path class="{{fill}}" id="Path_36997" data-name="Path 36997"
      d="M5.18,23.829s8.056,9,16.255.9l1.056-.9s-6.848-7.1-13.8-2.84Z" transform="translate(-0.838 -3.726)" />
    <circle class="{{fill}}" id="Ellipse_84" data-name="Ellipse 84" cx="4" cy="4" r="4"
      transform="translate(8.997 5)" />
  </g>
</svg>
}
@case('menu') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 23 19.6">
  <path class="{{fill}}" id="menu"
    d="M7,78.2a1.4,1.4,0,0,1,1.4-1.4H26.6a1.4,1.4,0,1,1,0,2.8H8.4A1.4,1.4,0,0,1,7,78.2Zm19.6,7H6.4a1.4,1.4,0,1,0,0,2.8H26.6a1.4,1.4,0,1,0,0-2.8Zm0,8.4H14a1.4,1.4,0,1,0,0,2.8H26.6a1.4,1.4,0,1,0,0-2.8Z"
    transform="translate(-5 -76.8)" />
</svg>
}
@case('twitter') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 18.163 17">
  <path id="path1009"
    d="M281.544,167.31l7.013,9.377L281.5,184.31h1.588l6.178-6.674,4.992,6.674h5.4l-7.407-9.9,6.569-7.1h-1.588l-5.69,6.147-4.6-6.147Zm2.336,1.17h2.483l10.964,14.66h-2.483Z"
    transform="translate(-281.5 -167.31)" fill="#404040" />
</svg>
}
@case('youtube') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 21 14.769">
  <g id="Group_290" data-name="Group 290" transform="translate(-7 -10.115)">
    <path class="{{fill}}" id="youtube"
      d="M11.331,17.609v-5.9L17,14.669l-5.672,2.94Zm12.458-6.922A4.533,4.533,0,0,0,22.954,8.6a3,3,0,0,0-2.1-.889C17.912,7.5,13.5,7.5,13.5,7.5H13.5s-4.408,0-7.346.212a3,3,0,0,0-2.1.889,4.541,4.541,0,0,0-.835,2.085,31.767,31.767,0,0,0-.21,3.4v1.593a31.784,31.784,0,0,0,.21,3.4,4.534,4.534,0,0,0,.835,2.084,3.553,3.553,0,0,0,2.315.9c1.68.16,7.14.21,7.14.21s4.413-.006,7.351-.218a3,3,0,0,0,2.1-.889,4.539,4.539,0,0,0,.835-2.084,31.784,31.784,0,0,0,.21-3.4V14.085a31.749,31.749,0,0,0-.21-3.4Z"
      transform="translate(4 2.615)" fill-rule="evenodd" />
  </g>
</svg>
}
@case('fb') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 8.505 17.5">
  <g id="Group_291" data-name="Group 291" transform="translate(-13.247 -8.75)">
    <path class="{{fill}}" id="facebook"
      d="M2.171,17.5V9.289H0V6.332H2.171V3.807C2.171,1.822,3.454,0,6.409,0A17.944,17.944,0,0,1,8.49.115l-.07,2.761s-.9-.009-1.887-.009c-1.066,0-1.237.491-1.237,1.306V6.332H8.505l-.14,2.957H5.3V17.5H2.171"
      transform="translate(13.247 8.75)" />
  </g>
</svg>
}
@case('instagram') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 21 21">
  <g id="Group_292" data-name="Group 292" transform="translate(-7 -7)">
    <path class="{{fill}}" id="instagram"
      d="M14,10.5A3.5,3.5,0,0,0,10.5,7,3.5,3.5,0,0,0,7,10.5,3.5,3.5,0,0,0,10.5,14,3.5,3.5,0,0,0,14,10.5Zm1.887,0A5.368,5.368,0,0,1,10.5,15.887,5.368,5.368,0,0,1,5.114,10.5,5.368,5.368,0,0,1,10.5,5.114,5.368,5.368,0,0,1,15.887,10.5Zm1.476-5.605a1.258,1.258,0,1,1-2.146-.888,1.258,1.258,0,0,1,2.146.888ZM10.5,1.887,9.454,1.879q-.95-.007-1.443,0t-1.32.041a10.782,10.782,0,0,0-1.408.137,5.433,5.433,0,0,0-.977.254,3.576,3.576,0,0,0-2,2,5.487,5.487,0,0,0-.253.978,10.782,10.782,0,0,0-.136,1.408q-.034.827-.041,1.32t0,1.443q.007.95.007,1.046T1.88,11.547q-.007.95,0,1.443t.041,1.319a10.782,10.782,0,0,0,.136,1.408,5.454,5.454,0,0,0,.253.976,3.576,3.576,0,0,0,2,2,5.487,5.487,0,0,0,.978.253,10.782,10.782,0,0,0,1.408.137q.827.034,1.32.041t1.443,0l1.046-.007,1.046.007q.95.007,1.443,0t1.319-.041a10.78,10.78,0,0,0,1.408-.137,5.487,5.487,0,0,0,.978-.253,3.576,3.576,0,0,0,2-2,5.487,5.487,0,0,0,.253-.978,10.782,10.782,0,0,0,.136-1.408q.034-.827.041-1.319t0-1.443q-.007-.95-.007-1.046t.007-1.046q.007-.95,0-1.443T19.08,6.691a10.78,10.78,0,0,0-.136-1.408,5.382,5.382,0,0,0-.254-.977,3.576,3.576,0,0,0-2-2,5.487,5.487,0,0,0-.978-.253,10.782,10.782,0,0,0-1.408-.136q-.827-.034-1.319-.041t-1.443,0L10.5,1.887ZM21,10.5q0,3.131-.068,4.334a6.413,6.413,0,0,1-1.7,4.4,6.413,6.413,0,0,1-4.4,1.7Q13.631,21,10.5,21t-4.334-.068a6.413,6.413,0,0,1-4.4-1.7,6.413,6.413,0,0,1-1.7-4.4Q0,13.631,0,10.5T.068,6.166a6.413,6.413,0,0,1,1.7-4.4,6.413,6.413,0,0,1,4.4-1.7Q7.369,0,10.5,0t4.334.068a6.413,6.413,0,0,1,4.4,1.7,6.413,6.413,0,0,1,1.7,4.4Q21,7.369,21,10.5Z"
      transform="translate(7 7)" />
  </g>
</svg>
}
@case('linkedin') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 21 20.074">
  <g id="Group_293" data-name="Group 293" transform="translate(-7 -7.463)">
    <path class="{{fill}}" id="linkedin"
      d="M4.767,20.074V6.53H.265V20.074ZM2.517,4.679A2.347,2.347,0,1,0,2.546,0a2.346,2.346,0,1,0-.059,4.679h.029ZM7.259,20.074h4.5V12.51a3.081,3.081,0,0,1,.148-1.1,2.464,2.464,0,0,1,2.31-1.647c1.629,0,2.281,1.242,2.281,3.063v7.246H21V12.308c0-4.16-2.221-6.1-5.183-6.1A4.483,4.483,0,0,0,11.73,8.494h.03V6.53h-4.5c.059,1.271,0,13.544,0,13.544Z"
      transform="translate(7 7.463)" />
  </g>
</svg>
}
@case('book') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20">
  <g id="Group_1651" data-name="Group 1651" transform="translate(-1629 -40)">
    <rect id="Rectangle_12467" data-name="Rectangle 12467" width="20" height="20" transform="translate(1629 40)"
      fill="none" />
    <g id="open-book" transform="translate(1630 6.952)">
      <g id="Group_1647" data-name="Group 1647" transform="translate(0 35.048)">
        <g id="Group_1646" data-name="Group 1646" transform="translate(0 0)">
          <path class="{{fill}}" id="Path_37163" data-name="Path 37163"
            d="M35.475,35.049h0a.593.593,0,0,0-.451.218.812.812,0,0,0-.19.532V47.036a.705.705,0,0,0,.645.749,7.329,7.329,0,0,1,5.744,2.478V38.5a.809.809,0,0,0-.089-.38A6.164,6.164,0,0,0,35.475,35.049Z"
            transform="translate(-32.722 -35.049)" />
          <path class="{{fill}}" id="Path_37164" data-name="Path 37164"
            d="M163.14,47.035V35.8a.812.812,0,0,0-.19-.532.593.593,0,0,0-.451-.218h0a6.165,6.165,0,0,0-5.658,3.074.809.809,0,0,0-.088.38v11.76a7.329,7.329,0,0,1,5.744-2.478A.705.705,0,0,0,163.14,47.035Z"
            transform="translate(-147.251 -35.048)" />
          <path class="{{fill}}" id="Path_37165" data-name="Path 37165"
            d="M178.445,71.8h-.467v9.4a1.8,1.8,0,0,1-1.642,1.913,6.517,6.517,0,0,0-4.854,1.928,13.39,13.39,0,0,1,6.819.153.576.576,0,0,0,.546-.145.8.8,0,0,0,.242-.585V72.55A.7.7,0,0,0,178.445,71.8Z"
            transform="translate(-161.089 -69.209)" />
          <path class="{{fill}}" id="Path_37166" data-name="Path 37166"
            d="M1.111,81.2V71.8H.644A.7.7,0,0,0,0,72.55V84.46a.8.8,0,0,0,.242.585.575.575,0,0,0,.546.145,13.389,13.389,0,0,1,6.819-.153,6.516,6.516,0,0,0-4.854-1.928A1.8,1.8,0,0,1,1.111,81.2Z"
            transform="translate(0 -69.209)" />
        </g>
      </g>
    </g>
  </g>
</svg>
}
@case('headphone') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20">
  <g id="Group_1643" data-name="Group 1643" transform="translate(-1629 -40)">
    <g id="headphones_1_" data-name="headphones (1)" transform="translate(1630 26.816)">
      <g id="Group_1638" data-name="Group 1638" transform="translate(0.449 24.009)">
        <g id="Group_1637" data-name="Group 1637">
          <path class="{{fill}}" id="Path_37160" data-name="Path 37160"
            d="M15.751,265.22a1.135,1.135,0,0,0-1.217,1.029v.575h-.018a1.639,1.639,0,0,0-1.757,1.486V270.3a1.639,1.639,0,0,0,1.757,1.486h.018v.575a1.135,1.135,0,0,0,1.217,1.029,1.135,1.135,0,0,0,1.217-1.029V266.25A1.135,1.135,0,0,0,15.751,265.22Z"
            transform="translate(-12.758 -265.22)" />
        </g>
      </g>
      <g id="Group_1640" data-name="Group 1640" transform="translate(13.342 24.009)">
        <g id="Group_1639" data-name="Group 1639" transform="translate(0 0)">
          <path class="{{fill}}" id="Path_37161" data-name="Path 37161"
            d="M381.948,266.826h-.018v-.575a1.234,1.234,0,0,0-2.434,0v6.116a1.234,1.234,0,0,0,2.434,0v-.575h.018A1.639,1.639,0,0,0,383.7,270.3v-1.993A1.639,1.639,0,0,0,381.948,266.826Z"
            transform="translate(-379.495 -265.221)" />
        </g>
      </g>
      <g id="Group_1642" data-name="Group 1642" transform="translate(0 15.184)">
        <g id="Group_1641" data-name="Group 1641" transform="translate(0 0)">
          <path class="{{fill}}" id="Path_37162" data-name="Path 37162"
            d="M9,15.184A9.018,9.018,0,0,0,0,24.22a9.154,9.154,0,0,0,.081,1.211,2.636,2.636,0,0,1,.2-.191,2.859,2.859,0,0,1,.912-.516A2.045,2.045,0,0,1,1.9,23.5l.058-.047a7.079,7.079,0,0,1,14.081,0l.058.047a2.045,2.045,0,0,1,.706,1.222,2.859,2.859,0,0,1,.912.516,2.632,2.632,0,0,1,.2.191A9.154,9.154,0,0,0,18,24.22,9.018,9.018,0,0,0,9,15.184Z"
            transform="translate(0 -15.184)" />
        </g>
      </g>
    </g>
    <rect id="Rectangle_12467" data-name="Rectangle 12467" width="20" height="20" transform="translate(1629 40)"
      fill="none" />
  </g>
</svg>
}
@case('zoom-in') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 22.017 22">
  <path class="{{fill}}" id="d43d6cbabca35c92696e08c90016cadf"
    d="M16.3,10.8H13V7.5a1.1,1.1,0,0,0-2.2,0v3.3H7.5a1.1,1.1,0,1,0,0,2.2h3.3v3.3a1.1,1.1,0,1,0,2.2,0V13h3.3a1.1,1.1,0,1,0,0-2.2Zm7.382,11.321L19.6,18.076A9.9,9.9,0,1,0,18.076,19.6l4.049,4.049a1.093,1.093,0,1,0,1.562-1.529ZM11.9,19.6a7.7,7.7,0,1,1,7.7-7.7,7.7,7.7,0,0,1-7.7,7.7Z"
    transform="translate(-1.979 -1.979)" />
</svg>
}
@case('zoom-out') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 22.017 22">
  <path class="{{fill}}" id="d43d6cbabca35c92696e08c90016cadf"
    d="M16.3,10.8H7.5a1.1,1.1,0,1,0,0,2.2h8.8a1.1,1.1,0,1,0,0-2.2Zm7.382,11.321L19.6,18.076A9.9,9.9,0,1,0,18.076,19.6l4.049,4.049a1.093,1.093,0,1,0,1.562-1.529ZM11.9,19.6a7.7,7.7,0,1,1,7.7-7.7,7.7,7.7,0,0,1-7.7,7.7Z"
    transform="translate(-1.979 -1.979)" />
</svg>
}
@case('download') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 15.067 22.399">
  <path class="{{fill}}" id="Union_1" data-name="Union 1"
    d="M-9987.549,14090.65a1.118,1.118,0,0,1-1.117-1.118,1.114,1.114,0,0,1,1.117-1.113h12.834a1.113,1.113,0,0,1,1.113,1.113,1.116,1.116,0,0,1-1.113,1.118Zm6.306-3.675a.176.176,0,0,1-.039,0l-.021,0a.28.28,0,0,1-.049-.007l-.046-.011-.018,0-.043-.01c-.014-.008-.028-.011-.042-.019l-.018,0-.043-.018c-.014-.008-.024-.011-.039-.018l-.018-.008-.042-.021-.036-.021-.018-.011a.323.323,0,0,1-.038-.024.338.338,0,0,1-.039-.028l-.018-.015a.168.168,0,0,1-.032-.021c-.028-.024-.053-.049-.078-.074h0l-6.419-6.418a1.122,1.122,0,0,1,0-1.581,1.124,1.124,0,0,1,.789-.325,1.123,1.123,0,0,1,.792.325l4.509,4.513v-13.8a1.118,1.118,0,0,1,1.117-1.118,1.119,1.119,0,0,1,1.118,1.118v13.8l4.508-4.513a1.127,1.127,0,0,1,.793-.325,1.12,1.12,0,0,1,.788.325,1.12,1.12,0,0,1,0,1.581l-6.422,6.422c-.024.024-.05.046-.078.07a.353.353,0,0,1-.031.021l-.018.015a.451.451,0,0,1-.039.028.341.341,0,0,1-.039.024l-.018.011a.24.24,0,0,0-.035.021l-.043.021-.018.008a.292.292,0,0,0-.039.018.23.23,0,0,1-.038.014l-.021.007c-.015.008-.028.011-.043.019l-.042.01-.021,0-.042.011a.336.336,0,0,1-.05.007l-.021,0a.171.171,0,0,1-.039,0,.516.516,0,0,1-.109.008A.516.516,0,0,1-9981.243,14086.976Z"
    transform="translate(9988.667 -14068.251)" />
</svg>
}
@case('share') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 16.498 18">
  <path class="{{fill}}" id="Union_7" data-name="Union 7"
    d="M10.5,15a3,3,0,0,1,.086-.712l-5.5-3.134a3,3,0,1,1,0-4.307l5.5-3.137A3,3,0,1,1,11.3,5.035L5.871,8.128a3,3,0,0,1,0,1.747L11.3,12.967A3,3,0,1,1,10.5,15Z" />
</svg>
}
@case('close') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 20 20">
  <path class="{{fill}}" id="close_1_" data-name="close (1)"
    d="M11.833,10.136,19.62,2.348A1.3,1.3,0,0,0,17.788.516L10,8.3,2.212.516A1.3,1.3,0,0,0,.38,2.348l7.788,7.788L.38,17.924a1.3,1.3,0,1,0,1.832,1.832L10,11.968l7.788,7.788a1.3,1.3,0,1,0,1.832-1.832Zm0,0"
    transform="translate(0 -0.136)" />
</svg>
}
@case('comma') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 49.726 42.314">
  <path id="Path_36995" data-name="Path 36995"
    d="M-14.2-24.086H6.03V-43.853H-3.544c0-9.729,1.7-15.134,8.957-15.6L2.942-66.4c-12.818.309-16.678,9.42-16.987,26.253C-14.2-35.205-14.2-29.646-14.2-24.086Zm29.5,0h20.23V-43.853H25.952c0-9.729,1.7-15.134,8.957-15.6L32.438-66.4c-12.818.309-16.678,9.42-16.987,26.253C15.3-35.205,15.3-29.646,15.3-24.086Z"
    transform="translate(14.2 66.4)" fill="#3367b1" opacity="0.3" />
</svg>
}
@case('secure') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 19 20">
  <path class="{{fill}}" id="secure"
    d="M26.03.114a1.292,1.292,0,0,0-1.061,0L17.516,3.252A2.438,2.438,0,0,0,16,5.5c.02,3.9,1.635,11.025,8.455,14.266a2.435,2.435,0,0,0,2.09,0C33.365,16.524,34.98,9.4,35,5.5a2.438,2.438,0,0,0-1.516-2.247Zm3.942,8.1-5.067,5.027a.951.951,0,0,1-1.342,0l-2.533-2.514a.934.934,0,0,1,0-1.331.955.955,0,0,1,1.342,0l1.86,1.846,4.394-4.364a.951.951,0,0,1,1.342,0,.937.937,0,0,1,0,1.332Z"
    transform="translate(-16)" />
</svg>
}
@case('miniatures') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 25.534 41.672">
  <g id="Group_3476" data-name="Group 3476" transform="translate(-9.752 682.534)">
    <g id="g5167" transform="translate(11.568 -678.284)">
      <path id="path5169"
        d="M-18.783,0A2.434,2.434,0,0,0-20,2.109v.324A1.217,1.217,0,0,0-18.783,3.65a1.217,1.217,0,0,0,1.217-1.217V2.109A2.434,2.434,0,0,0-18.783,0Z"
        transform="translate(20)" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"
        stroke-width="1.2" />
    </g>
    <g id="g5171" transform="translate(10.352 -674.633)">
      <path id="path5173"
        d="M-112.7-118.961h-6.084A1.217,1.217,0,0,1-120-120.177a1.217,1.217,0,0,1,1.217-1.217h1.217v-5.272"
        transform="translate(120 126.666)" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"
        stroke-width="1.2" />
    </g>
    <g id="g5175" transform="translate(24.952 -652.733)">
      <path id="path5177" d="M0,0V10.3l2.433.811" fill="none" stroke="#404040" stroke-linecap="round"
        stroke-linejoin="round" stroke-width="1.2" />
    </g>
    <g id="g5179" transform="translate(17.652 -652.733)">
      <path id="path5181" d="M-37.567,0V10.3L-40,11.113" transform="translate(40)" fill="none" stroke="#404040"
        stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
    </g>
    <g id="g5183" transform="translate(18.868 -658.816)">
      <path id="path5185" d="M-112.7,0H-120" transform="translate(120)" fill="none" stroke="#404040"
        stroke-linecap="round" stroke-linejoin="round" stroke-width="1.2" />
    </g>
    <g id="g5187" transform="translate(17.652 -668.55)">
      <path id="path5189"
        d="M-125.222,0h-6.489a1.622,1.622,0,0,0-1.622,1.622V4.056a2.326,2.326,0,0,0,1.217,2.028V14.6a1.217,1.217,0,0,0,1.217,1.217,1.217,1.217,0,0,0,1.217-1.217V13.175a3.643,3.643,0,0,0,1.217.209,3.643,3.643,0,0,0,1.217-.209V14.6a1.217,1.217,0,0,0,1.217,1.217,1.217,1.217,0,0,0,1.217-1.217V6.083A2.326,2.326,0,0,0-123.6,4.056V1.622A1.622,1.622,0,0,0-125.222,0Z"
        transform="translate(133.333 0)" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"
        stroke-width="1.2" />
    </g>
    <g id="g5191" transform="translate(27.385 -669.361)">
      <path id="path5193" d="M-75.133,0h1.217A1.217,1.217,0,0,1-72.7,1.217a1.217,1.217,0,0,1-1.217,1.217H-80"
        transform="translate(80)" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"
        stroke-width="1.2" />
    </g>
    <g id="g5195" transform="translate(18.868 -681.934)">
      <path id="path5197"
        d="M-112.7-112.7v-3.65a2.91,2.91,0,0,0-1.217-2.433,2.55,2.55,0,0,0-.583.544A7.689,7.689,0,0,0-116.35-120a7.689,7.689,0,0,0-1.85,1.761,2.55,2.55,0,0,0-.583-.544A2.91,2.91,0,0,0-120-116.35v3.65"
        transform="translate(120 120)" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"
        stroke-width="1.2" />
    </g>
    <g id="g5199" transform="translate(18.868 -678.284)">
      <path id="path5201"
        d="M-112.7-56.35a3.65,3.65,0,0,1-3.65,3.65A3.65,3.65,0,0,1-120-56.35,3.65,3.65,0,0,1-116.35-60,3.65,3.65,0,0,1-112.7-56.35Z"
        transform="translate(120 60)" fill="none" stroke="#404040" stroke-linecap="round" stroke-linejoin="round"
        stroke-width="1.2" />
    </g>
  </g>
</svg>
}
@case('paintings') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 39.06 29.224">
  <path id="Union_9" data-name="Union 9"
    d="M.072,28.828a.6.6,0,0,1-.6-.6V.206a.6.6,0,0,1,.6-.6H37.928a.6.6,0,0,1,.6.6v28.02a.6.6,0,0,1-.6.6Zm.6-1.2H37.326V.809H.674Zm2.416-1.367a.585.585,0,0,1-.586-.586V2.761a.585.585,0,0,1,.586-.586H34.909a.587.587,0,0,1,.587.586v22.91a.586.586,0,0,1-.587.586ZM8.436,17.61,3.677,21.9v3.183H34.323V19.149l-5.571-4.864a2.148,2.148,0,0,0-2.8-.036l-6.667,5.533a3.332,3.332,0,0,1-3.856.283L11,17.369a2.17,2.17,0,0,0-2.565.241ZM3.677,20.324,7.651,16.74a3.351,3.351,0,0,1,3.959-.373l4.432,2.7a2.157,2.157,0,0,0,2.5-.183l6.667-5.533a3.317,3.317,0,0,1,4.316.055l4.8,4.192V3.347H3.677ZM5.9,9.125a3.767,3.767,0,1,1,3.767,3.767A3.767,3.767,0,0,1,5.9,9.125Zm1.172,0a2.6,2.6,0,1,0,2.6-2.6A2.6,2.6,0,0,0,7.07,9.125Z"
    transform="translate(0.53 0.396)" fill="#404040" />
</svg>
}
@case('arms') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 32.843 38">
  <path id="shield"
    d="M67.584,8.922a.593.593,0,0,0-.317-.517L51.444.072a.593.593,0,0,0-.553,0L35.068,8.4a.593.593,0,0,0-.317.516c-.1,6.935,1.295,12.929,4.142,17.817A29.929,29.929,0,0,0,50.887,37.932a.593.593,0,0,0,.563,0A29.926,29.926,0,0,0,63.441,26.739C66.289,21.851,67.682,15.857,67.584,8.922ZM51.168,36.734C40.829,31.052,35.844,22.069,35.934,9.29L51.168,1.267,66.4,9.29C66.49,22.07,61.5,31.053,51.168,36.734ZM62.9,11,51.444,4.965a.594.594,0,0,0-.553,0L39.434,11a.593.593,0,0,0-.316.552c.436,9.6,4.274,16.587,11.731,21.346a.593.593,0,0,0,.638,0c7.458-4.76,11.295-11.742,11.731-21.346A.594.594,0,0,0,62.9,11ZM51.168,31.69c-6.8-4.468-10.354-10.958-10.846-19.817L51.168,6.161l10.846,5.712C61.523,20.731,57.971,27.22,51.168,31.69Z"
    transform="translate(-34.747 -0.003)" fill="#404040" />
</svg>
}
@case('eye') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 25.164 15">
  <g id="eye" transform="translate(112 -144.725)">
    <path class="{{fill}}" id="Path_93147" data-name="Path 93147"
      d="M12.582,98.725c-4.808,0-9.168,2.63-12.385,6.9a1,1,0,0,0,0,1.189c3.217,4.278,7.577,6.908,12.385,6.908s9.168-2.63,12.385-6.9a1,1,0,0,0,0-1.189C21.75,101.355,17.39,98.725,12.582,98.725Zm.345,12.781a5.293,5.293,0,1,1,4.937-4.937A5.3,5.3,0,0,1,12.927,111.506Zm-.16-2.44a2.85,2.85,0,1,1,2.661-2.661A2.845,2.845,0,0,1,12.767,109.066Z"
      transform="translate(-112 46)" />
  </g>
</svg>
}
@case('external-link') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 14 14">
  <g id="external-link" transform="translate(-0.001 0)">
    <path class="{{fill}}" id="Path_93146" data-name="Path 93146"
      d="M10.112,14H2.334A2.333,2.333,0,0,1,0,11.667V3.889A2.333,2.333,0,0,1,2.334,1.556H5.445a.778.778,0,0,1,0,1.556H2.334a.778.778,0,0,0-.778.778v7.778a.778.778,0,0,0,.778.778h7.778a.778.778,0,0,0,.778-.778V8.556a.778.778,0,0,1,1.556,0v3.111A2.333,2.333,0,0,1,10.112,14ZM5.445,9.333A.778.778,0,0,1,4.9,8.006l6.45-6.45H8.557A.778.778,0,0,1,8.557,0h4.667a.775.775,0,0,1,.543.221h0l0,0h0l0,0,0,0h0l0,0h0A.775.775,0,0,1,14,.778V5.444a.778.778,0,0,1-1.556,0V2.656L6,9.106a.775.775,0,0,1-.55.228Z" />
  </g>
</svg>
}
@case('document_upload') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 -960 960 960">
  <path class="{{fill}}"
    d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z" />
</svg>
}
@case('day-night') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 22 22">
  <g id="day-and-night" transform="translate(-150.005 -76.053)">
    <g id="Solid" transform="translate(150.005 76.053)">
      <path class="{{fill}}" id="Path_93510" data-name="Path 93510"
        d="M154.374,93.619a.787.787,0,0,0-.424,1.333,10.587,10.587,0,1,0,14.959-14.985.785.785,0,0,0-1.33.424,17.017,17.017,0,0,1-13.2,13.228Zm1.844-2.029a17.318,17.318,0,0,0,9.309-9.142,6.965,6.965,0,0,0-9.309,9.142Zm-5.427-2.78h2.664a.787.787,0,0,0,0-1.574h-2.664a.787.787,0,0,0,0,1.574Zm2.714-8.137,1.884,1.886a.786.786,0,1,0,1.111-1.113l-1.884-1.887a.787.787,0,0,0-1.111,1.114Zm7.664-3.832v2.668a.786.786,0,1,0,1.572,0V76.84a.786.786,0,1,0-1.572,0Z"
        transform="translate(-150.005 -76.053)" fill-rule="evenodd" />
    </g>
  </g>
</svg>
}
@case('sunrise') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 24 12.923">
  <path id="Path_94518" data-name="Path 94518"
    d="M21.462,21a.923.923,0,0,1-.923.923H9.462A.923.923,0,0,1,8.538,21a6.462,6.462,0,0,1,12.923,0ZM15,12.692a.923.923,0,0,0,.923-.923V9.923a.923.923,0,0,0-1.846,0v1.846A.923.923,0,0,0,15,12.692ZM9.129,13.818l-1.311-1.3a.923.923,0,0,0-1.3,1.3l1.3,1.311a.927.927,0,1,0,1.311-1.311Zm-3.36,6.258H3.923a.923.923,0,0,0,0,1.846H5.769a.923.923,0,1,0,0-1.846Zm20.308,0H24.231a.923.923,0,1,0,0,1.846h1.846a.923.923,0,1,0,0-1.846Zm-2.594-7.56a.923.923,0,0,0-1.3,0l-1.311,1.3a.927.927,0,0,0,1.311,1.311l1.3-1.311a.923.923,0,0,0,0-1.3Z"
    transform="translate(-3 -9)" class="{{fill}}" />
</svg>
}
@case('noon') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 23.999 24">
  <path id="Union_14" data-name="Union 14"
    d="M-3130.925,14848.076v-1.845a.924.924,0,0,1,.924-.924.924.924,0,0,1,.924.924v1.845a.924.924,0,0,1-.924.925A.924.924,0,0,1-3130.925,14848.076Zm8.105-2.594-1.308-1.3a.927.927,0,0,1-.276-.655.931.931,0,0,1,.276-.656.924.924,0,0,1,.652-.269.933.933,0,0,1,.656.269l1.3,1.312a.916.916,0,0,1,0,1.3.915.915,0,0,1-.648.269A.923.923,0,0,1-3122.82,14845.482Zm-15.615-.048a.922.922,0,0,1-.049-1.253l1.3-1.312a.94.94,0,0,1,.656-.269.94.94,0,0,1,.656.269.927.927,0,0,1,.272.656.923.923,0,0,1-.272.655l-1.312,1.3a.915.915,0,0,1-.6.224A.926.926,0,0,1-3138.434,14845.435Zm1.971-8.434a6.462,6.462,0,0,1,6.462-6.462,6.46,6.46,0,0,1,6.462,6.462,6.46,6.46,0,0,1-6.462,6.462A6.462,6.462,0,0,1-3136.463,14837Zm15.693.92h-.048a.917.917,0,0,1-.873-.919.921.921,0,0,1,.92-.925h1.845a.924.924,0,0,1,.924.925.92.92,0,0,1-.876.919h-1.892Zm-20.306,0h-.048a.92.92,0,0,1-.877-.919.924.924,0,0,1,.924-.925h1.844a.925.925,0,0,1,.924.925.92.92,0,0,1-.877.919h-1.892Zm16.948-6.793a.917.917,0,0,1-.276-.656.915.915,0,0,1,.276-.652l1.308-1.3a.914.914,0,0,1,1.3,0,.914.914,0,0,1,0,1.3l-1.3,1.309a.924.924,0,0,1-.652.269h-.018A.892.892,0,0,1-3124.128,14831.128Zm-13.054,0-1.3-1.309a.925.925,0,0,1,.049-1.256.92.92,0,0,1,1.252-.044l1.312,1.3a.911.911,0,0,1,.272.652.914.914,0,0,1-.272.656.931.931,0,0,1-.652.269h-.017A.906.906,0,0,1-3137.182,14831.128Zm6.257-3.357v-1.845a.924.924,0,0,1,.924-.925.924.924,0,0,1,.924.925v1.845a.923.923,0,0,1-.924.92A.923.923,0,0,1-3130.925,14827.771Z"
    transform="translate(3142.001 -14825.001)" class="{{fill}}" />
</svg>
}
@case('sunset') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 23.999 17.241">
  <path id="Union_15" data-name="Union 15"
    d="M-3133.578,14800.243a.568.568,0,0,1-.568-.567.569.569,0,0,1,.568-.572h7.158a.571.571,0,0,1,.568.572.57.57,0,0,1-.568.567Zm-1.712-2.095a.637.637,0,0,1-.635-.635.636.636,0,0,1,.635-.636h10.578a.636.636,0,0,1,.639.636.638.638,0,0,1-.639.635Zm14.523-2.225a.922.922,0,0,1-.925-.921.923.923,0,0,1,.925-.925h1.846a.922.922,0,0,1,.921.925.921.921,0,0,1-.921.921Zm-14.772,0a.921.921,0,0,1-.921-.921,6.462,6.462,0,0,1,6.459-6.463,6.463,6.463,0,0,1,6.463,6.463.922.922,0,0,1-.925.921Zm-5.538,0a.921.921,0,0,1-.921-.921.922.922,0,0,1,.921-.925h1.846a.925.925,0,0,1,.925.925.924.924,0,0,1-.925.921Zm17.6-6.526a.934.934,0,0,1-.654-.268.926.926,0,0,1-.275-.657.93.93,0,0,1,.275-.653l1.311-1.3a.919.919,0,0,1,1.3,0,.918.918,0,0,1,0,1.3l-1.3,1.311a.934.934,0,0,1-.654.268Zm-13.052,0h0a.925.925,0,0,1-.65-.268l-1.3-1.311a.928.928,0,0,1,.044-1.256.927.927,0,0,1,1.256-.045l1.311,1.3a.927.927,0,0,1,.271.653.922.922,0,0,1-.271.657.934.934,0,0,1-.654.268Zm5.605-3.629v-1.842a.922.922,0,0,1,.921-.925.922.922,0,0,1,.925.925v1.842a.925.925,0,0,1-.925.925A.924.924,0,0,1-3130.922,14785.769Z"
    transform="translate(3141.999 -14783.002)" class="{{fill}}" />
</svg>
}
@case('night') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 17.558 18">
  <path id="Path_94519" data-name="Path 94519"
    d="M30.531,23.578a.827.827,0,0,0-.939-.111,6.4,6.4,0,0,1-7.884-9.78.826.826,0,0,0,.091-.941.81.81,0,0,0-.833-.41,9.045,9.045,0,0,0,.844,17.978c.162.009.323.013.484.013a9.024,9.024,0,0,0,8.462-5.845h0A.812.812,0,0,0,30.531,23.578Z"
    transform="translate(-13.251 -12.326)" class="{{fill}}" />
</svg>
}
@case('pattern') {
<svg class="d-block" viewBox="0 0 263 320.004">
  <g id="Sikh_Arch_-_Outline" data-name="Sikh Arch - Outline" transform="translate(-1402 -3581)">
    <path id="Exclusion_1" data-name="Exclusion 1"
      d="M1280,21772v0H1017v-320h263v320Zm-201.9-261.922a25.065,25.065,0,0,0-5.517.717,17.957,17.957,0,0,0-8.944,4.754c-3.024,3.137-4.4,7.711-4.1,13.594a4.875,4.875,0,0,1-3.524,4.967,16.806,16.806,0,0,0-5.308,3.057,18.059,18.059,0,0,0-6.276,11.5c-.347,2.4-.6,4.744-.831,6.809-1.135,10.32-2.307,20.99-13.589,23.922V21759h237v-179.6c-11.282-2.932-12.455-13.6-13.589-23.922-.226-2.062-.483-4.4-.831-6.809a18.074,18.074,0,0,0-6.277-11.5,16.808,16.808,0,0,0-5.306-3.057,4.875,4.875,0,0,1-3.524-4.967c.3-5.883-1.08-10.457-4.1-13.594a18,18,0,0,0-8.947-4.756,25.121,25.121,0,0,0-5.515-.715h-.041c-.047,0-.092,0-.136,0a4.858,4.858,0,0,1-4.731-3.758c-1.357-5.525-3.953-9.437-7.715-11.627a18.435,18.435,0,0,0-9.378-2.26,26.676,26.676,0,0,0-6.378.766l-.025.008a5.08,5.08,0,0,1-1.435.209,4.741,4.741,0,0,1-2.477-.689,4.856,4.856,0,0,1-2.262-3.219c-.24-1.09-2.75-10.646-16.846-10.8a32.514,32.514,0,0,1-11.341-2.014,23.64,23.64,0,0,1-7.636-4.7,23.771,23.771,0,0,1-7.646,4.7,32.552,32.552,0,0,1-11.347,2.014c-14.09.154-16.6,9.711-16.84,10.8a4.87,4.87,0,0,1-2.257,3.219,4.772,4.772,0,0,1-2.484.689,5.079,5.079,0,0,1-1.433-.209l-.018-.006-.007,0a26.686,26.686,0,0,0-6.378-.766,18.436,18.436,0,0,0-9.378,2.26c-3.763,2.189-6.358,6.1-7.715,11.627a4.861,4.861,0,0,1-4.731,3.758c-.047,0-.094,0-.141,0Z"
      transform="translate(385 -17871)" fill="rgba(255,255,255,0)" />
    <path id="Path_93506" data-name="Path 93506"
      d="M247.768,92.783a23.921,23.921,0,0,0-15.86-19.515C233.3,47.137,207.059,47.8,207.059,47.8c-6.083-24.912-31.185-17.511-31.185-17.511s-3-15.252-22.721-15.459S131.5,0,131.5,0s-1.932,14.616-21.653,14.831S87.126,30.29,87.126,30.29s-25.1-7.4-31.185,17.511c0,0-26.236-.665-24.849,25.466a23.921,23.921,0,0,0-15.86,19.515C12.963,108.374,14.416,120.451,0,121.426V320H263V121.426c-14.416-.975-12.964-13.051-15.232-28.642m-241,220.389V125.811c11.866-3.082,13.1-14.279,14.3-25.147.253-2.305.516-4.694.872-7.149A18.774,18.774,0,0,1,34.137,78.205a5.14,5.14,0,0,0,3.712-5.219c-.319-6.174,1.125-10.98,4.322-14.288,5.642-5.846,15.175-5.753,15.251-5.753a5.111,5.111,0,0,0,5.136-3.935c1.425-5.809,4.153-9.923,8.118-12.227,7.19-4.179,16.535-1.584,16.61-1.565a5.11,5.11,0,0,0,6.505-3.888c.253-1.143,2.906-11.2,17.725-11.356,9.393-.1,15.794-3.176,19.985-7.055,4.19,3.879,10.592,6.952,19.984,7.055,14.819.16,17.472,10.213,17.725,11.356a5.111,5.111,0,0,0,6.505,3.888c.075-.018,9.42-2.614,16.61,1.565,3.965,2.3,6.692,6.418,8.118,12.227a5.111,5.111,0,0,0,5.137,3.935c.075,0,9.608-.093,15.251,5.753,3.2,3.308,4.639,8.113,4.321,14.288a5.14,5.14,0,0,0,3.712,5.219,18.772,18.772,0,0,1,12.195,15.309c.356,2.455.619,4.844.872,7.149,1.2,10.868,2.437,22.065,14.3,25.147V313.172Z"
      transform="translate(1402 3581)" class="{{fill}}" />
  </g>
</svg>
}
@case('star') {
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [attr.fill]="fill">
  <path
    d="M394 480a16 16 0 01-9.39-3L256 383.76 127.39 477a16 16 0 01-24.55-18.08L153 310.35 23 221.2a16 16 0 019-29.2h160.38l48.4-148.95a16 16 0 0130.44 0l48.4 149H480a16 16 0 019.05 29.2L359 310.35l50.13 148.53A16 16 0 01394 480z" />
</svg>
}
@case('star-outline') {
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [attr.fill]="fill">
  <path d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z" fill="#d6d3d1" stroke="#d6d3d1"
    stroke-linejoin="round" stroke-width="1.2" />
</svg>
}
@case('star-half') {
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [attr.fill]="fill">
  <path d="M480 208H308L256 48l-52 160H32l140 96-54 160 138-100 138 100-54-160z" fill="#d6d3d1" stroke="#d6d3d1"
    stroke-linejoin="round" stroke-width="1.2" />
  <path d="M256 48v316L118 464l54-160-140-96h172l52-160z" />
</svg>
}
@case('play') {
<svg xmlns="http://www.w3.org/2000/svg" [attr.fill]="fill" height="24" viewBox="0 -960 960 960" width="24">
  <path d="M320-200v-560l440 280-440 280Zm80-280Zm0 134 210-134-210-134v268Z" />
</svg>
}
@case('top-arrow') {
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1792 1792" [attr.width]="width" [attr.height]="height">
  <path class="{{fill}}"
    d="M1395 1312q0 13-10 23l-50 50q-10 10-23 10t-23-10l-393-393-393 393q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l466 466q10 10 10 23zm0-384q0 13-10 23l-50 50q-10 10-23 10t-23-10l-393-393-393 393q-10 10-23 10t-23-10l-50-50q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l466 466q10 10 10 23z" />
</svg>
}
@case('down-arrow-reverse') {
<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 120.64" [attr.width]="width" [attr.height]="height">
  <g>
    <path class="{{fill}}"
      d="M108.91,54.03c1.63-1.55,3.74-2.31,5.85-2.28c2.11,0.03,4.2,0.84,5.79,2.44l0.12,0.12c1.5,1.58,2.23,3.6,2.2,5.61 c-0.03,2.01-0.82,4.02-2.37,5.55c-17.66,17.66-35.61,35.13-53.4,52.68c-0.05,0.07-0.1,0.13-0.16,0.19 c-1.63,1.55-3.76,2.31-5.87,2.28c-2.11-0.03-4.21-0.85-5.8-2.45l-0.26-0.27C37.47,100.43,19.87,82.98,2.36,65.46 C0.82,63.93,0.03,61.93,0,59.92c-0.03-2.01,0.7-4.03,2.21-5.61l0.15-0.15c1.58-1.57,3.66-2.38,5.76-2.41 c2.1-0.03,4.22,0.73,5.85,2.28l47.27,47.22L108.91,54.03L108.91,54.03z M106.91,2.26c1.62-1.54,3.73-2.29,5.83-2.26 c2.11,0.03,4.2,0.84,5.79,2.44l0.12,0.12c1.5,1.57,2.23,3.6,2.21,5.61c-0.03,2.01-0.82,4.02-2.37,5.55 C101.2,31.01,84.2,48.87,67.12,66.39c-0.05,0.07-0.11,0.14-0.17,0.21c-1.63,1.55-3.76,2.31-5.87,2.28 c-2.11-0.03-4.21-0.85-5.8-2.45C38.33,48.94,21.44,31.36,4.51,13.83l-0.13-0.12c-1.54-1.53-2.32-3.53-2.35-5.54 C2,6.16,2.73,4.14,4.23,2.56l0.15-0.15C5.96,0.84,8.05,0.03,10.14,0c2.1-0.03,4.22,0.73,5.85,2.28l45.24,47.18L106.91,2.26 L106.91,2.26z" />
  </g>
</svg>
}
@case ('down-arrow') {
<svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 92 92" id="down-arrow">
  <path class="{{fill}}"
    d="M73.8 57.9l-25 24.9C48 83.6 47 84 46 84s-2-.4-2.8-1.2l-25-24.9c-1.6-1.6-1.6-4.1 0-5.7 1.6-1.6 4.1-1.6 5.7 0L42 70.4V12c0-2.2 1.8-4 4-4s4 1.8 4 4v58.4l18.2-18.1c1.6-1.6 4.1-1.6 5.7 0 1.5 1.5 1.5 4-.1 5.6z">
  </path>
</svg>
}
@case ('visibility_off') {
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-eye-slash"
  viewBox="0 0 16 16">
  <path
    d="M13.359 11.238C15.06 9.72 16 8 16 8s-3-5.5-8-5.5a7.028 7.028 0 0 0-2.79.588l.77.771A5.944 5.944 0 0 1 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.134 13.134 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755-.165.165-.337.328-.517.486l.708.709z" />
  <path
    d="M11.297 9.176a3.5 3.5 0 0 0-4.474-4.474l.823.823a2.5 2.5 0 0 1 2.829 2.829l.822.822zm-2.943 1.299.822.822a3.5 3.5 0 0 1-4.474-4.474l.823.823a2.5 2.5 0 0 0 2.829 2.829z" />
  <path
    d="M3.35 5.47c-.18.16-.353.322-.518.487A13.134 13.134 0 0 0 1.172 8l.195.288c.335.48.83 1.12 1.465 1.755C4.121 11.332 5.881 12.5 8 12.5c.716 0 1.39-.133 2.02-.36l.77.772A7.029 7.029 0 0 1 8 13.5C3 13.5 0 8 0 8s.939-1.721 2.641-3.238l.708.709zm10.296 8.884-12-12 .708-.708 12 12-.708.708z" />
</svg>
}
@case ('visibility_on') {
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-eye"
  viewBox="0 0 16 16">
  <path
    d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
  <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
</svg>
}
@case ('unmute') {
<svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 -960 960 960">
  <path class="{{fill}}"
    d="M560-131v-82q90-26 145-100t55-168q0-94-55-168T560-749v-82q124 28 202 125.5T840-481q0 127-78 224.5T560-131ZM120-360v-240h160l200-200v640L280-360H120Zm440 40v-322q47 22 73.5 66t26.5 96q0 51-26.5 94.5T560-320ZM400-606l-86 86H200v80h114l86 86v-252ZM300-480Z" />
</svg>
}
@case ('mute') {
<svg xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 -960 960 960">
  <path class="{{fill}}"
    d="M792-56 671-177q-25 16-53 27.5T560-131v-82q14-5 27.5-10t25.5-12L480-368v208L280-360H120v-240h128L56-792l56-56 736 736-56 56Zm-8-232-58-58q17-31 25.5-65t8.5-70q0-94-55-168T560-749v-82q124 28 202 125.5T840-481q0 53-14.5 102T784-288ZM650-422l-90-90v-130q47 22 73.5 66t26.5 96q0 15-2.5 29.5T650-422ZM480-592 376-696l104-104v208Zm-80 238v-94l-72-72H200v80h114l86 86Zm-36-130Z" />
</svg>
}
@case ('done-check') {
<svg class="d-block" [attr.width]="width" [attr.height]="height" viewBox="0 0 60 60" fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path fill-rule="evenodd" clip-rule="evenodd"
    d="M30 3C44.9117 3 57 15.0883 57 30C57 44.9117 44.9117 57 30 57C15.0883 57 3 44.9117 3 30C3 15.0883 15.0883 3 30 3Z"
    stroke="#22c55e" stroke-width="6" stroke-linecap="round" stroke-linejoin="round" />
  <path d="M17.1428 28.867L27.7311 39L42.8571 21" stroke="#22c55e" stroke-width="6" stroke-linecap="round"
    stroke-linejoin="round" />
</svg>
}
@case ('error') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height"
  viewBox="0 0 100 100">
  <path stroke="null" id="svg_4" fill="red"
    d="m29.66375,98.807l-28.47075,-28.47075l0,-40.6725l28.47075,-28.47075l40.6725,0l28.47075,28.47075l0,40.6725l-28.47075,28.47075l-40.6725,0zm4.8807,-25.75925l15.45555,-15.45555l15.45555,15.45555l7.5922,-7.5922l-15.45555,-15.45555l15.45555,-15.45555l-7.5922,-7.5922l-15.45555,15.45555l-15.45555,-15.45555l-7.5922,7.5922l15.45555,15.45555l-15.45555,15.45555l7.5922,7.5922zm-0.27115,14.91325l31.4534,0l22.2343,-22.2343l0,-31.4534l-22.2343,-22.2343l-31.4534,0l-22.2343,22.2343l0,31.4534l22.2343,22.2343zm15.7267,-37.961z" />
</svg>
}
@case ('play-rounded') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 30 30">
  <path class="{{fill}}" id="Path_97313" data-name="Path 97313"
    d="M15,0A15,15,0,1,0,30,15,15.017,15.017,0,0,0,15,0Zm5.963,15.526-8.75,5.625a.625.625,0,0,1-.963-.526V9.375a.625.625,0,0,1,.963-.526l8.75,5.625a.625.625,0,0,1,0,1.051Z" />
</svg>
}
@case ('pause-rounded') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" [attr.width]="width" [attr.height]="height" viewBox="0 0 30 30">
  <path class="{{fill}}"
    d="M15,0C6.8,0,0,6.8,0,15s6.8,15,15,15s15-6.8,15-15S23.2,0,15,0z M13.5,21h-3V9h3V21z M19.5,21h-3V9h3V21z" />
</svg>
}
@case ('previous') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" [attr.width]="width"
  [attr.height]="height">
  <path d="M640-80 240-480l400-400 71 71-329 329 329 329-71 71Z" class="{{fill}}" />
</svg>
}
@case ('next') {
<svg class="d-block" xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" [attr.width]="width"
  [attr.height]="height">
  <path d="m321-80-71-71 329-329-329-329 71-71 400 400L321-80Z" class="{{fill}}" />
</svg>
}

}