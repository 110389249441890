import { Routes } from '@angular/router';
import { LoginGuard } from '@guards/login.guard';

export const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('@core/routes/page.routes').then((m) => m.pageRoutes)
  },
  {
    path: 'reset-password/:token',
    title: 'Reset Password',
    loadComponent: () =>
      import('@auth/reset-password/reset-password.component').then(
        (m) => m.ResetPasswordComponent
      ),
    canMatch: [LoginGuard]
  },
  {
    path: 'sign-out',
    loadComponent: () =>
      import('@auth/logout/logout.component').then((m) => m.LogoutComponent)
  },
  {
    path: '**',
    loadComponent: () =>
      import('@pages/not-found/not-found.component').then(
        (m) => m.NotFoundComponent
      )
  }
];
