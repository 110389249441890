import { DOCUMENT } from '@angular/common';
import {
  ComponentRef,
  Inject,
  Injectable,
  Type,
  ViewContainerRef
} from '@angular/core';
import { DcDirective } from '@directives/dc.directive';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DialogService {
  vc!: ViewContainerRef;
  dialog$ = new Subject<{
    component: Type<unknown>;
    data: unknown;
  }>();
  showPopup$ = new Subject<boolean>();

  constructor(@Inject(DOCUMENT) private document: Document) {}

  displayPopup() {
    this.showPopup$.next(true);
  }

  hidePopup() {
    this.showPopup$.next(false);
  }

  confirmUserDecision(confirm: boolean): void {
    if (confirm) {
      this.document.location.reload();
    }
    this.hidePopup();
  }

  open(
    component: Type<unknown>,
    option: { dcContainer: DcDirective; data: unknown }
  ) {
    this.vc = option.dcContainer.viewContainerRef;
    this.vc.clear();

    const componentRef = this.vc.createComponent(component);
    (componentRef as ComponentRef<{ data: unknown }>).instance.data =
      option.data;
  }

  close() {
    this.vc?.clear();
  }
}
