import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  showLoader$ = new Subject<boolean>();

  constructor(private toasterService: ToastrService) {}

  displayLoader() {
    this.showLoader$.next(true);
  }

  hideLoader() {
    this.showLoader$.next(false);
  }

  success(message: string) {
    this.toasterService.success(message);
  }

  error(message: string) {
    this.toasterService.error(message);
  }
}
