import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SwUpdate } from '@angular/service-worker';
import { DialogService } from './dialog.service';

@Injectable({
  providedIn: 'root'
})
export class SWUpdateService {
  #destroyRef = inject(DestroyRef);
  newVersion = '';

  constructor(
    private dialogService: DialogService,
    private swUpdate: SwUpdate
  ) {}

  initSW(): void {
    this.versionUpdates();
  }

  versionUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.versionUpdates
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe((event) => {
          if (event.type === 'VERSION_READY') {
            this.newVersion = (
              event.latestVersion.appData as { version: string }
            )?.version;
            this.flushAndReload();
          }
        });
    }
  }

  flushAndReload() {
    this.dialogService.displayPopup();
  }
}
