import { isPlatformBrowser } from '@angular/common';
import { HttpResponse } from '@angular/common/http';
import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';

import { STORAGE } from '@constants/storage.constant';
import { CryptoService } from '@services/crypto.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  cacheAPIs = new Map<string, HttpResponse<unknown>>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: InjectionToken<object>,
    @Inject('STORAGE') private storage: Storage,
    private cryptoService: CryptoService
  ) {}

  get(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      try {
        const encryptedValue = this.storage.getItem(key);
        return (
          encryptedValue &&
          JSON.parse(this.cryptoService.decryptValue(encryptedValue))
        );
      } catch {
        return null;
      }
    }
  }

  set(key: string, value: object | string) {
    if (isPlatformBrowser(this.platformId)) {
      const encryptedValue = this.cryptoService.encryptValue(
        JSON.stringify(value)
      );
      this.storage.setItem(key, encryptedValue);
    }
  }

  getUserId() {
    return this.get(STORAGE.USER_DATA)._id;
  }

  remove(key: string) {
    if (isPlatformBrowser(this.platformId)) {
      this.storage.removeItem(key);
    }
  }

  clear() {
    if (isPlatformBrowser(this.platformId)) {
      this.storage.clear();
    }
  }
}
