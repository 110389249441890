import { NgClass, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators
} from '@angular/forms';
import { finalize } from 'rxjs';

import { IconsComponent } from '@components/icons/icons.component';
import {
  FADE_ANIMATION,
  OPEN_MODEL_ANIMATION
} from '@constants/animation.constants';
import { REGEX } from '@constants/app.constants';
import { environment } from '@environment/environment';
import { PasswordType, SignUpForm } from '@models/auth.model';
import { AuthService } from '@services/auth.service';
import { DialogService } from '@services/dialog.service';
import { EnvironmentLoaderService } from '@services/environment-loader.service';
import { ToasterService } from '@services/toaster.service';
import { CustomValidatorService } from '@services/validator.service';
import { VcButtonComponent } from '@vc-libs/vc-button/vc-button.component';
import { VcInputComponent } from '@vc-libs/vc-input/vc-input.component';
import { ReCaptchaV3Service } from 'ng-recaptcha-2';

const modules = [ReactiveFormsModule];
const components = [VcButtonComponent, VcInputComponent, IconsComponent];

@Component({
  selector: 'app-sign-up',
  standalone: true,
  imports: [NgClass, NgTemplateOutlet, ...modules, ...components],
  providers: [ReCaptchaV3Service],
  templateUrl: './sign-up.component.html',
  animations: [FADE_ANIMATION, OPEN_MODEL_ANIMATION]
})
export class SignUpComponent implements OnInit {
  isSubmitted = false;
  signUpForm!: FormGroup<SignUpForm>;
  passwordFieldType: PasswordType = 'password';
  confirmPasswordFieldType: PasswordType = 'password';

  readonly emailRegex = REGEX.EMAIL;
  readonly passwordRegex = REGEX.PASSWORD;
  readonly logo = EnvironmentLoaderService.environmentContent.logo;
  readonly awsUrl = environment.awsUrl;
  #destroyRef = inject(DestroyRef);

  constructor(
    private authService: AuthService,
    private toasterService: ToasterService,
    private customValidatorService: CustomValidatorService,
    private dialogService: DialogService,
    private reCaptchaV3Service: ReCaptchaV3Service
  ) {}

  get formControls(): SignUpForm {
    return this.signUpForm.controls;
  }

  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm(): void {
    this.signUpForm = new FormGroup<SignUpForm>(
      {
        firstName: new FormControl('', [
          Validators.required,
          this.customValidatorService.notWhitespace
        ]),
        lastName: new FormControl('', [
          Validators.required,
          this.customValidatorService.notWhitespace
        ]),
        email: new FormControl('', [
          Validators.required,
          this.customValidatorService.notWhitespace,
          Validators.pattern(REGEX.EMAIL)
        ]),
        password: new FormControl('', [
          Validators.required,
          this.customValidatorService.notWhitespace,
          Validators.pattern(REGEX.PASSWORD)
        ]),
        confirmPassword: new FormControl('', [
          Validators.required,
          this.customValidatorService.notWhitespace
        ])
      },
      this.customValidatorService.passwordMatchValidator()
    );
  }

  signUp(): boolean | void {
    this.signUpForm.markAllAsTouched();
    if (this.signUpForm.invalid) {
      return;
    }
    this.isSubmitted = true;
    const formValue = {
      firstName: this.formControls.firstName.value,
      lastName: this.formControls.lastName.value,
      email: this.formControls.email.value,
      password: this.formControls.password.value
    };
    this.reCaptchaV3Service.execute('signUp').subscribe({
      next: (captcha) => {
        const param = { ...formValue, captcha };
        this.authService
          .signUp(param)
          .pipe(
            takeUntilDestroyed(this.#destroyRef),
            finalize(() => (this.isSubmitted = false))
          )
          .subscribe((res) => {
            this.toasterService.success(res.message);
            this.signUpForm.reset();
            this.popupClose();
          });
      }
    });
  }

  popupClose() {
    this.dialogService.close();
  }

  backToLogin() {
    this.authService.openLogin();
  }

  togglePasswordVisibility(): void {
    this.passwordFieldType =
      this.passwordFieldType === 'password' ? 'text' : 'password';
  }

  toggleConfirmPasswordVisibility(): void {
    this.confirmPasswordFieldType =
      this.confirmPasswordFieldType === 'password' ? 'text' : 'password';
  }
}
