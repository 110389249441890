<div class="backdrop-blur-[2px] fixed flex justify-center items-center inset-0 z-50">
  <div @fade class="fixed inset-0 bg-black/50" (click)="popupClose()" aria-hidden="true">
  </div>
  <div @openModel
    class="bg-white rounded-lg w-[480px] md:w-[585px] px-4 pb-4 pt-8 md:p-8 lg:p-10 mx-auto max-w-[calc(100%-32px)] max-h-[calc(100%-32px)] overflow-auto relative top-0">
    <div class="cursor-pointer absolute top-3 right-3" (click)="popupClose()" aria-hidden="true">
      <app-icons icons="close" width="16" height="16" />
    </div>
    <div class="flex items-center justify-between">
      <div class="pr-4">
        <strong class="block text-blue-custom text-lg md:text-xl lg:text-2xl uppercase">Sign
          up</strong>
        <p class="text-black text-sm lg:text-base mt-1">User details.</p>
      </div>
      <img loading="eager" fetchpriority="high" class="w-[80px] sm:w-[100px] md:w-[120px]" [src]="awsUrl+logo"
        alt="logo">
    </div>
    <form class="mt-6 md:mt-7 lg:mt-8 xl:mt-10" [formGroup]="signUpForm">
      <div class="grid gap-y-4 md:gap-y-5">
        <div>
          <app-vc-input formControlName="firstName" type="text" placeholder="First Name" name="firstName" [customClass]="{
            'w-full px-4 text-neutral-700 rounded-md border outline-none min-h-[40px] border-grey-50' : true, 'border-red text-red' : formControls.firstName.touched && formControls.firstName.invalid
          }" />
          <ng-container *ngTemplateOutlet="requiredError;
          context: { control: formControls.firstName, message: 'First Name is required' }" />
        </div>
        <div>
          <app-vc-input formControlName="lastName" type="text" placeholder="Last Name" name="lastName" [customClass]="{
            'w-full px-4 text-neutral-700 rounded-md border outline-none min-h-[40px] border-grey-50' : true, 'border-red text-red' : formControls.lastName.touched && formControls.lastName.invalid
          }" />
          <ng-container *ngTemplateOutlet="requiredError;
          context: { control: formControls.lastName, message: 'Last Name is required' }" />
        </div>
        <div>
          <app-vc-input formControlName="email" type="email" placeholder="Email Address" name="email" [customClass]="{
            'w-full px-4 text-neutral-700 rounded-md border outline-none min-h-[40px] border-grey-50' : true, 'border-red text-red' : formControls.email.touched && formControls.email.invalid
          }" [pattern]="emailRegex" autocomplete="email" />
          @if(formControls.email.touched && formControls.email.errors?.required) {
          <p class="text-red mt-1 text-xs md:text-sm"> Email is required </p>
          }
          @if(formControls.email.touched && formControls.email.errors?.pattern) {
          <p class="text-red mt-1 text-xs md:text-sm"> Please enter valid email </p>
          }
        </div>
        <div class="relative">
          <app-vc-input formControlName="password" [customClass]="{
            'w-full px-4 text-neutral-700 rounded-md border outline-none min-h-[40px] border-grey-50' : true, 'border-red text-red' : formControls.password.touched && formControls.password.invalid
          }" [type]="passwordFieldType" placeholder="Password" name="password" autocomplete="off" />
          <div class="absolute top-2 right-3">
            <app-icons [icons]="passwordFieldType === 'password' ? 'visibility_off' : 'visibility_on'"
              (click)="togglePasswordVisibility()" />
          </div>
          <ng-container *ngTemplateOutlet="requiredError;
          context: { control: formControls.password, message: 'Password is required' }" />
          <ng-container *ngTemplateOutlet="patternError; context: {
            control: formControls.password,
            message: 'Your password must be between 8 and 16 characters long and include at least one lowercase letter, one uppercase letter, one number, and one special character'
          }" />
        </div>
        <div class="relative">
          <app-vc-input formControlName="confirmPassword" [customClass]="{ 'w-full px-4 text-neutral-700 rounded-md border outline-none min-h-[40px] border-grey-50' : true, 'border-red text-red' : formControls.confirmPassword.touched && formControls.confirmPassword.invalid
        }" [type]="confirmPasswordFieldType" placeholder="Confirm Password" name="confirmPassword"
            autocomplete="off" />
          <div class="absolute top-2 right-3">
            <app-icons [icons]="confirmPasswordFieldType === 'password' ? 'visibility_off' : 'visibility_on'"
              (click)="toggleConfirmPasswordVisibility()" />
          </div>
          <ng-container *ngTemplateOutlet="requiredError; context: { control: formControls.confirmPassword,
          message: 'Confirm password is required' }" />
          <ng-container
            *ngTemplateOutlet="patternError; context: { control: formControls.confirmPassword, form: signUpForm, message: 'Password and confirm password does not match' }" />
        </div>
      </div>
      <div class="mt-6 md:mt-7 lg:mt-8 xl:mt-10 text-center">
        <app-vc-button type="submit"
          [class]="{ 'px-4 h-10 rounded-5 bg-primary text-white font-semibold rounded-md min-w-[100px] disabled:bg-grey-50 disabled:text-grey-light disabled:cursor-not-allowed' : true }"
          [isDisabled]="isSubmitted" [spin]="isSubmitted" (buttonTap)="signUp()">
          Create
        </app-vc-button>
      </div>
      <div class="mt-4 lg:mt-6 xl:mt-8 text-center">
        <p class="text-sm xl:text-base text-neutral-700">
          Already have an account?
          <app-vc-button [class]="{ 'underline uppercase font-medium' : true }" [isDisabled]="isSubmitted"
            (buttonTap)="backToLogin()">
            Sign in now
          </app-vc-button>
        </p>
      </div>
    </form>
  </div>
</div>

<ng-template #requiredError let-control="control" let-message="message">
  @if(control.touched && control.errors?.required) {
  <p class="text-red mt-1 text-xs md:text-sm">{{ message }}</p>
  }
</ng-template>

<ng-template #patternError let-control="control" let-form="form" let-message="message">
  @if(control.touched && (control.errors?.pattern) || (!control.errors?.required && form?.errors?.mismatch)) {
  <p class="text-red mt-1 text-xs md:text-sm">{{ message }}</p>
  }
</ng-template>