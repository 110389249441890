import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment/environment';
import { EnvironmentContent } from '@models/common.model';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentLoaderService {
  static environmentContent: EnvironmentContent;

  constructor(private httpClient: HttpClient) {}

  loadEnvironmentSettings() {
    const url = `assets/environments/environment.${environment.type}.json`;
    return this.httpClient
      .get<EnvironmentContent>(url)
      .pipe(tap((res) => (EnvironmentLoaderService.environmentContent = res)));
  }
}
