import { Injectable } from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CustomValidatorService {
  passwordMatchValidator(): ValidatorFn {
    return (group: AbstractControl): ValidationErrors | null => {
      const password: string = group.get('password')?.value;
      const confirmPassword: string = group.get('confirmPassword')?.value;
      return password === confirmPassword ? null : { mismatch: true };
    };
  }

  notWhitespace(control: AbstractControl): ValidationErrors | null {
    return control.value && control.value.trim().length === 0
      ? { required: true }
      : null;
  }
}
