import { Injectable } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { MetaDetail } from '@models/common.model';
import { EnvironmentLoaderService } from '@services/environment-loader.service';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  constructor(
    private title: Title,
    private meta: Meta
  ) {}

  updateMetaDescription(metaDetail: MetaDetail): void {
    const description =
      metaDetail.description ||
      `The mission of the ${EnvironmentLoaderService.environmentContent.title} is to showcase the rich Art and Culture
       of the Punjab and to leverage  the same to expose a worldwide audience to the history of the Sikhs 
      and the teachings of the Sikh Gurus`;
    this.title.setTitle(metaDetail.title);
    this.meta.updateTag({
      name: 'description',
      content: this.removeHTMLTagsFromString(description)
    });
    this.meta.updateTag({
      property: 'og:title',
      content: metaDetail.title
    });
    this.meta.updateTag({
      property: 'author',
      content: 'Anmol S. Mahal'
    });
    this.meta.updateTag({
      property: 'og:site_name',
      content: EnvironmentLoaderService.environmentContent.title
    });
    this.meta.updateTag({
      property: 'og:description',
      content: this.removeHTMLTagsFromString(description)
    });
    this.meta.updateTag({
      property: 'og:image',
      content: metaDetail.url
    });
    this.meta.updateTag({
      property: 'og:image:alt',
      content: metaDetail.title
    });
  }

  removeHTMLTagsFromString(value: string): string {
    return value.replace(/<[^>]*>?/gm, '');
  }
}
