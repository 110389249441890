import {
  provideHttpClient,
  withFetch,
  withInterceptors
} from '@angular/common/http';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  isDevMode
} from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling
} from '@angular/router';
import { provideServiceWorker } from '@angular/service-worker';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaModule } from 'ng-recaptcha-2';
import { shareIcons } from 'ngx-sharebuttons/icons';
import { ToastrModule } from 'ngx-toastr';

import { environment } from '@environment/environment';
import {
  HttpCacheInterceptor,
  HttpErrorInterceptor,
  HttpTokenInterceptor
} from '@interceptors/http.interceptor';
import { appRoutes } from '@routes/app.routes';
import { EnvironmentLoaderService } from '@services/environment-loader.service';
import { provideShareButtonsOptions } from 'ngx-sharebuttons';

const modules = [
  ToastrModule.forRoot({
    closeButton: true,
    newestOnTop: true,
    tapToDismiss: true,
    enableHtml: true,
    progressBar: true,
    progressAnimation: 'increasing',
    timeOut: 3000,
    easeTime: 300,
    positionClass: 'toast-top-right'
  }),
  RecaptchaModule
];

const recaptchaSecretKey = environment.recaptchaSecretKey;

export const appConfig: ApplicationConfig = {
  providers: [
    provideAnimations(),
    importProvidersFrom(...modules),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        HttpTokenInterceptor,
        HttpErrorInterceptor,
        HttpCacheInterceptor
      ])
    ),
    provideRouter(
      appRoutes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      }),
      withComponentInputBinding()
    ),
    provideShareButtonsOptions(
      shareIcons()
    ),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerImmediately'
    }),
    { provide: 'STORAGE', useFactory: getStorage },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: recaptchaSecretKey },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeApp,
      deps: [EnvironmentLoaderService],
      multi: true
    }
  ]
};

export function getStorage() {
  return typeof window !== 'undefined' ? window.localStorage : null;
}

function initializeApp(environmentLoader: EnvironmentLoaderService) {
  return () => environmentLoader.loadEnvironmentSettings();
}
