import { ViewportScroller } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ActivatedRoute,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router,
  RouterOutlet
} from '@angular/router';
import { APP } from '@constants/app.constants';
import { IdleTimeDirective } from '@directives/idel-time.directive';

import { AuthService } from '@services/auth.service';
import { EnvironmentLoaderService } from '@services/environment-loader.service';
import { SeoService } from '@services/seo.service';
import { SWUpdateService } from '@services/sw-update.service';
import { ToasterService } from '@services/toaster.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, IdleTimeDirective],
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  showLoader = true;
  #destroyRef = inject(DestroyRef);
  delayTime = APP.ROUTE_DELAY;
  idleTimer = APP.IDLE_TIMER;

  idleTimeout: number | undefined;
  idlePromptTimeout: number | undefined;

  constructor(
    private authService: AuthService,
    private router: Router,
    private toasterService: ToasterService,
    private swUpdateService: SWUpdateService,
    private seoService: SeoService,
    private viewportScroller: ViewportScroller,
    private route: ActivatedRoute
  ) {
    this.swUpdateService.initSW();
    this.router.events.subscribe((routerEvent) => {
      if (routerEvent instanceof NavigationStart) {
        this.showLoader = true;
      }
      const eventType = [NavigationEnd, NavigationCancel, NavigationError];
      if (eventType.some((event) => routerEvent instanceof event)) {
        this.showLoader = false;
      }
      if (routerEvent instanceof NavigationEnd) {
        const metaDetail = {
          title: EnvironmentLoaderService.environmentContent.title,
          url: '/assets/images/logo.svg'
        };
        this.seoService.updateMetaDescription(metaDetail);
      }
    });
  }

  onIdle() {
    this.showIdlePrompt();
  }

  checkRouterFragment() {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        setTimeout(() => {
          this.viewportScroller.scrollToAnchor(fragment);
        }, APP.TIMEOUT);
      }
    });
  }

  showIdlePrompt() {
    const userResponse = confirm(
      'You have been idle for a while. Do you want to continue your session?'
    );
    if (userResponse) {
      this.resetIdleTimer();
    } else {
      this.idleTimeout = window.setTimeout(() => {
        window.location.reload();
      }, 0);
    }
  }

  resetIdleTimer() {
    if (this.idleTimeout !== undefined) {
      clearTimeout(this.idleTimeout);
    }
    if (this.idlePromptTimeout !== undefined) {
      clearTimeout(this.idlePromptTimeout);
    }
  }

  ngOnInit() {
    this.toasterService.showLoader$.subscribe((res) => {
      setTimeout(() => {
        this.showLoader = res;
        this.checkRouterFragment();
      }, 0);
    });
    this.authService
      .getSiteSettings()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe({
        next: (res) => (this.authService.siteSettings = res.data),
        error: () =>
          (this.authService.siteSettings =
            EnvironmentLoaderService.environmentContent.configuration)
      });
  }
}
