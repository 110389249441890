<div class="backdrop-blur-[2px] fixed flex justify-center items-center inset-0 z-50">
  <div @fade class="fixed inset-0 bg-black/50" (click)="popupClose()" aria-hidden="true">
  </div>
  <div @openModel
    class="bg-white rounded-lg w-[480px] md:w-[585px] px-4 pb-4 pt-8 md:p-8 lg:p-10 mx-auto max-w-[calc(100%-32px)] max-h-[calc(100%-32px)] overflow-auto relative top-0">
    <div class="cursor-pointer absolute top-3 right-3" (click)="popupClose()" aria-hidden="true">
      <app-icons icons="close" width="16" height="16" />
    </div>
    <div class="flex items-center justify-between">
      <div class="pr-4">
        <strong class="block text-blue-custom text-lg md:text-xl lg:text-2xl uppercase">Sign
          in</strong>
        <p class="text-black text-sm lg:text-base mt-1">Please enter your credentials</p>
      </div>
      <img loading="eager" fetchpriority="high" class="w-[80px] sm:w-[100px] md:w-[120px]" [src]="awsUrl+logo"
        alt="logo">
    </div>
    <form class="mt-6 md:mt-7 lg:mt-8 xl:mt-10" #loginForm="ngForm">
      <div class="grid gap-y-4 md:gap-y-5">
        <div>
          <app-vc-input
            [customClass]="{ 'w-full px-4 text-neutral-700 rounded-md border outline-none min-h-[40px] border-grey-50' : true, 'border-red text-red' : (loginForm.submitted || email.touched) && email.invalid }"
            type="email" name="email" placeholder="Email Address" [required]="true" ngModel #email="ngModel"
            autocomplete="email" />
          @if((loginForm.submitted || email.touched) && email.errors?.required){
          <p class="text-red mt-1 text-xs md:text-sm"> Email is required </p>
          }
        </div>
        <div class="relative">
          <app-vc-input
            [customClass]="{ 'w-full px-4 text-neutral-700 rounded-md border outline-none min-h-[40px] border-grey-50' : true, 'border-red text-red' : (loginForm.submitted || password.touched) && password.invalid }"
            [type]="passwordFieldType" name="password" placeholder="Password" [required]="true" ngModel
            #password="ngModel" autocomplete="off" />
          <div class="absolute top-2 right-3">
            <app-icons [icons]="passwordFieldType === 'password' ? 'visibility_off' : 'visibility_on'"
              (click)="togglePasswordVisibility()" />
          </div>
          @if((loginForm.submitted || password.touched) && password.errors?.required) {
          <p class="text-red mt-1 text-xs md:text-sm"> Password is required </p>
          }
        </div>
      </div>
      <div class="mt-1.5">
        <div class="flex justify-between">
          <div class="flex items-center">
            <input type="checkbox" name="isRemember" id="isRemember" [ngModel]="false" #isRemember="ngModel">
            <label for="isRemember" class="text-sm text-neutral-700 ml-1 cursor-pointer"> Remember me </label>
          </div>
          <app-vc-button [class]="{ 'text-sm underline text-neutral-700' : true }" [isDisabled]="isSubmitted"
            (buttonTap)="openForgot()">
            Forgot Password
          </app-vc-button>
        </div>
      </div>
      <div class="mt-6 md:mt-7 lg:mt-8 xl:mt-10 text-center">
        <app-vc-button type="submit"
          [class]="{ 'px-4 h-10 rounded-5 bg-primary text-white font-semibold rounded-md min-w-[100px] disabled:bg-grey-50 disabled:text-grey-light disabled:cursor-not-allowed' : true }"
          [isDisabled]="isSubmitted" [spin]="isSubmitted" (buttonTap)="onSubmit(loginForm)">Sign in</app-vc-button>
      </div>
      <div class="mt-4 lg:mt-6 xl:mt-8 text-center">
        <p class="text-sm xl:text-base">
          Don't have an account?
          <app-vc-button [class]="{ 'underline uppercase font-medium' : true }" [isDisabled]="isSubmitted"
            (buttonTap)="openRegister()">
            Sign up now
          </app-vc-button>
        </p>
      </div>
    </form>
  </div>
</div>